import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueScrollTo from 'vue-scrollto'
import LottieVuePlayer from "@lottiefiles/vue-lottie-player"
import VueCollapse from 'vue2-collapse'
import Vuelidate from 'vuelidate'
import { API } from '@ananasbear/superapi'
import './assets/styles/style.sass'

Vue.prototype.$api = new API()

Vue.use(VueScrollTo, {
  container: 'body',
  duration: 100,
  easing: 'linear',
  offset: -60,
  force: true,
  onCancel: false,
  x: false
})
Vue.use(VueCollapse)
Vue.use(LottieVuePlayer)
Vue.use(Vuelidate)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/views/HomePage')
  },
  {
    path: '/services',
    name: 'ServicesPage',
    component: () => import('@/views/ServicesPage')
  },
  {
    path: '/cases',
    name: 'CasesPage',
    component: () => import('@/views/CasesPage')
  },
  {
    path: '/vacancies',
    name: 'VacanciesPage',
    component: () => import('@/views/VacanciesPage')
  },
  {
    path: '/contacts',
    name: 'ContactsPage',
    component: () => import('@/views/ContactsPage')
  },
  {
    path: '/about',
    name: 'AboutUsPage',
    component: () => import('@/views/AboutUsPage')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) return savedPosition
    return {x: 0, y: 0}
  },
  routes
})

export default router
